:root {
    --bs-offcanvas-width: '600px';
}

body { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; 
    background-color: rgba(0, 0, 0, 0.06); font-weight:300; padding-bottom: 20px; }

.app-view {
  background: rgb(26, 188, 156);
  margin: 0 auto;
  width: 600px;
  padding: 20px;
}

.app-view a { color: #fff; }
.app-view a.topic-title { font-size: 22px; text-decoration: none; }
.app-view .topic-html { color: #fff; }

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}
.dropzone-active {
  background-color: rgb(247, 213, 213);
}

.tab-pane:not(.card-body>.tab-content>.tab-pane) {
  padding: 20px;
  background-color: #fff;
  border-left: 1px rgb(222, 226, 230) solid;
  border-right: 1px rgb(222, 226, 230) solid;
  border-bottom: 1px rgb(222, 226, 230) solid;
}
.content-container { margin-top:70px; /*height: calc(100vh - 80px); overflow:auto;*/ }
.offcanvas { width:500px; }
.padding-10 { padding:10px; }
.working { opacity:.5; transition:opacity 0.2s ease; }
.drop-shadow {
  -webkit-box-shadow: 0px 5px 9px -1px rgba(130, 130, 130, 0.43);
  -moz-box-shadow: 0px 5px 9px -1px rgba(130, 130, 130, 0.43);
  box-shadow: 10px 5px 9px -1px rgba(130, 130, 130, 0.43);
}
.fade-in {
  -webkit-animation: fadein 1s;
  -moz-animation: fadein 1s;
  -ms-animation: fadein 1s;
  -o-animation: fadein 1s;
  animation: fadein 1s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}